"use client";

import clsx from "clsx";
import Link from "next/link";
import type { Session } from "next-auth";
import { useMemo, type MouseEventHandler } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { cva, type VariantProps } from "class-variance-authority";
import { faHeart as solidHeart } from "@fortawesome/free-solid-svg-icons";

import { useToast } from "@/components/ui/use-toast";
import { getPreferredMedia, getThumbnail, isUserAdmin, isUserPremium, slugify } from "@/lib/utils";
import { BlogLikesCollectionResponse, type BlogArticle } from "@/types";
import { useGetBlogLikesQuery } from "@/features/blog/blogApiInjection";

import MediaCard from "@/components/cards/MediaCard";
import LikeCircleIcon from "@/components/icons/LikeCircle";
import LoadingSpinner from "@/components/loadingComponents/loadingSpinner";
import { Card, CardTitle, CardContent } from "@/components/ui/card";

const cardVariants = cva("flex flex-col rounded-lg !shadow-custom", {
	variants: {
		intent: {
			grid: "h-[22rem]",
			carousel: "h-96 sm:w-[22rem]",
		},
	},
	defaultVariants: { intent: "grid" },
});

const previewVariants = cva("relative w-full rounded-t-lg p-0", {
	variants: {
		intent: {
			grid: "h-52",
			carousel: "h-56",
		},
	},
	defaultVariants: { intent: "grid" },
});

type Props = VariantProps<typeof cardVariants> & {
	data: BlogArticle;
	session: Session | null;
	favorite?: {
		isFavorite: boolean;
		isLoading: boolean;
		isUpdating: boolean;
		onClick: (id: BlogArticle) => Promise<void>;
	};
	expansion?: {
		/**
		 * Configures the base path used when the card is clicked.
		 */
		basePathname: string;
	};
};

export default function ArticleCard({
	session,
	data,
	favorite,
	intent = "grid",
	expansion = { basePathname: "/blogs" },
}: Props) {
	const { toast } = useToast();
	const { data: likes, isLoading: isLikesLoading } = useGetBlogLikesQuery({
		id: data._id,
		limit: 1,
	});

	const media = useMemo(() => getPreferredMedia(data.media), [data.media]);
	const thumbnail = getThumbnail(data.thumbnail);

	const isBlogPremiumButNotUser =
		data.isPremium &&
		!isUserPremium(session?.user.plan.duration) &&
		!isUserAdmin(session?.user.role);

	const slug = slugify(data.title);
	const href = `${expansion.basePathname}/${data.id}/${slug}`;

	const mediaCardChild = (
		<MediaCard
			data={media}
			className="contents"
			childClassName="rounded-t-lg size-full object-cover"
			thumbnail={thumbnail}
			disablePlayback={isBlogPremiumButNotUser}
		/>
	);

	return (
		<Card className={cardVariants({ intent, className: "relative" })}>
			{media?.type === "VIDEO" ? (
				<div className={previewVariants({ intent })}>{mediaCardChild}</div>
			) : (
				<Link
					href={media?.type === "VIDEO" ? "" : href}
					className={previewVariants({ intent })}
				>
					{mediaCardChild}
				</Link>
			)}

			{favorite && (
				<div className="absolute right-2 top-2">
					<button onClick={() => favorite.onClick(data)} className="-mt-0.5">
						{favorite.isLoading || favorite.isUpdating ? (
							<LoadingSpinner className="text-tertiary/20" />
						) : (
							<FontAwesomeIcon
								size="lg"
								icon={favorite.isFavorite ? solidHeart : solidHeart}
								className={
									favorite.isFavorite ? "text-red-500" : "text-tertiary/20"
								}
							/>
						)}
					</button>
				</div>
			)}

			<Link href={href} className="contents">
				<div className="inline-flex h-10 items-center justify-between px-4 text-xsm">
					<span className=" text-muted-foreground">
						{data.blogCategories
							.slice(0, 2)
							.map((x) => x.name)
							.join(" | ")}
					</span>
					<div
						className={clsx(
							"flex h-6 w-20 items-center justify-center rounded-full font-medium uppercase",
							data.isPremium ? "bg-black text-tertiary" : "bg-tertiary text-white",
						)}
					>
						{data.isPremium ? "Premium" : "Free"}
					</div>
				</div>

				<CardTitle
					className={clsx("inline-flex flex-1 px-4 text-sm font-medium leading-snug", {
						"pt-1": intent === "grid",
						"pt-2": intent === "carousel",
					})}
				>
					{data.title}
				</CardTitle>

				<CardContent className="inline-flex h-6 items-center justify-between px-4 text-xs text-grey-foreground">
					<Likes isLikesLoading={isLikesLoading} likes={likes} />
					<span>{data.readingTime}</span>
				</CardContent>
			</Link>
		</Card>
	);
}

type LikesProps = {
	isLikesLoading: boolean;
	likes?: BlogLikesCollectionResponse;
};

export function Likes({ isLikesLoading, likes }: LikesProps) {
	let child = null;

	if (isLikesLoading) {
		child = (
			<>
				<LikeCircleIcon className="size-5" />
				<div className="h-4 w-12 animate-pulse rounded-md bg-muted" />
			</>
		);
	}

	if (likes !== undefined && likes.totalDocs >= 10) {
		child = (
			<>
				<LikeCircleIcon className="size-5" />

				<span>{likes?.totalDocs}</span>
			</>
		);
	}

	return <div className="flex items-center gap-2">{child}</div>;
}
